<template>
  <div class='container'>
    <div class="box">
      <div class="box-title">测试人信息</div>
      <div class="message">
        <p>{{testing.trueName}} <span style="font-weight: 400;color: #333;font-size: 3.4667vw;">{{testing.age}}岁</span>  <span style="background: #EFF9F8;padding: .8vw 2.1333vw;color:#10CA9B ;font-size: 2.9333vw;border-radius: .8vw;">{{gradeList[testing.grade]}}{{testing.studyClass}}</span></p>
        <span>学籍号：{{testing.sn?testing.sn:'-'}}</span>
        <span>测评时间：{{testing.created_at}}</span>
      </div>
    </div>
    <div class="box">
      <div class="box-title">报告详情</div>
      <div class="report" v-if="testing.gotStatus &&testing.reportStatus == 10 &&testing.reportItems.length > 0">
        <div class="content"  v-for="(item, index) in testing.reportItems" :key="index">
          <h2>{{ item.title }}</h2>
          <template v-if="item.data.length > 0">
            <!-- 指数标题 -->
            <div class="report-item" style="background-color: #5EDBC9;">
              <div  v-for="(pro, index) in item.data[0].length === 4
              ? projectAttributes : projectAttributes1" :class="['pro-item',item.data[0].length === 4?'pro-item-four':'']" style="color: #fff;">
                {{ pro }}
              </div>
            </div>
            <!-- 分数 -->
            <div class="report-item" v-for="(rowItem,rowIndex) in item.data" :key="rowIndex">
              <div class="col-item" v-for="(colItem,colIndex) in rowItem" :key="colIndex">
                {{ colItem }}
              </div>
            </div>
            <!-- 圆形图 -->
            <div class="circle-box">
              <div style="font-size:2.9333vw;transform: scale(.9);">{{ item.title }}</div>
              <div class="status">
                {{ item.sumTitle?item.sumTitle:'无' }}
              </div>
            </div>
            <!-- tips -->
            <div style="text-align:center;color: #F0A248;" v-if="item.sumTips" v-html="item.sumTips"></div>
            <!-- 正常得分标准 -->
            <div class="target" v-if="item.good.length !== 0">
              <div class="target-title" style="background-color: #EFF9F8;color: #5EDBC9 ;" >正常指标 &nbsp; (你的分数/得分范围)</div>
              <div class="target-box" v-for="(goodItem,goodIndex) in item.good" :key="goodIndex">
                <p style="margin: 2.6667vw 0 ;">
                  {{ goodItem.name }}： <span style="font-size: 4.8vw;font-weight: 550;">{{goodItem.score}}</span>/{{ goodItem.totalScore }}
                </p>
                <van-progress :percentage="(goodItem.score/goodItem.totalScore)*100" stroke-width="6" :show-pivot="false" color="#5EDBC9"/>
                <div class="target-intro" style="line-height: 6.4vw;color: #666;" v-if="goodItem.intro" v-html="goodItem.intro" ></div>
              </div>
            </div>
            <!-- 异常得分标准 -->
            <div class="target" v-if="item.bad.length !== 0">
              <div class="target-title" style="background-color: #FFF7ED;color:#F0A248">异常指标 &nbsp; (你的分数/得分范围)</div>
              <div class="target-box" v-for="(badItem,badIndex) in item.bad" :key="badIndex">
                <p style="margin: 2.6667vw 0 ;">
                  {{ badItem.name }}： {{badItem.score}}/{{ badItem.totalScore }}
                </p>
                <van-progress :percentage="(badItem.score/badItem.totalScore)*100" stroke-width="6" :show-pivot="false" color="#5EDBC9"/>
                <div class="target-intro" style="line-height: 6.4vw" v-if="badItem.intro" v-html="badItem.intro" ></div>
              </div>
            </div>
          </template>
        </div>
      </div>
      <van-empty description="暂未获取报告" v-else></van-empty>
    </div>
    <div class="bottom" >
      <button @click="$router.push({path:'index',query:{type:2}})">返回报告列表</button>
    </div>
  </div>
</template>

<script>
import { reportDetail } from '@/api/report.js'
export default {
  data(){
    return{
      testing:{docReport: ''},
      projectAttributes: ['指标', '你的分数', '健康值范围', '得分范围'],
      projectAttributes1: ['指标', '你的分数', '得分范围'],
      gradeList: ['一年级', '二年级', '三年级', '四年级', '五年级', '六年级', '初一', '初二', '初三', '高一', '高二', '高三'],
    }
  },
  created(){
    this.getDetail()
  },
  methods:{
    async getDetail(){
      let {id}=this.$route.query
      const res = await reportDetail({id})
      this.testing=res.data.testing
      if (this.testing.reportItems && (this.testing.reportItems[0].analysisItem instanceof Array === false)) {
        this.testing.reportItems[0].analysisItem?this.testing.reportItems[0].analysisItem = JSON.parse(this.testing.reportItems[0].analysisItem):''
      }
    }
  }
}
</script>
<style lang='less' scoped>
.container{
  padding:5.3333vw 0 16.5333vw 0;
  min-height: 100%;
  background-color: #fff;
  .box{
    .box-title{
      position: relative;
      display: flex;
      align-items: center;
      padding-left: 4vw;
      font-size: 4.2667vw;
      font-weight: 550;
      color: #FB6D3F;
      &::before{
        content: '';
        position: absolute;
        left: 0;
        width: .8vw;
        height: 4vw;
        border-radius: .8vw;
        background-color: #FB6D3F;
      }
    }
    .message{
      margin-top: 6.4vw;
      padding: 0 8vw;
      font-size: 3.4667vw;
      display: flex;
      flex-direction: column;
      p{
        font-weight: 550;
        font-size: 4.2667vw;
        margin-bottom: 2.6667vw;
      }
      span{
        color: rgba(121, 121, 121, 1);
        margin-bottom: 5.3333vw;
      }
    }
    .report{
      padding: 0 4vw;
      .content{
        margin-top: 4vw;
        h2{
          margin: 4.2667vw 0 5.3333vw 0;
          text-align: center;
          font-size: 4vw;
          font-weight: 400;
          color: #666;
        }
        .report-item{
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          min-height: 11.2vw;
          padding: 0 5.3333vw;
          font-size: 3.4667vw;
          color: #5EDBC9;
          &:nth-of-type(1){
            font-weight: 550;
            background-color: rgba(130, 130, 130, 1);
          }
          &:nth-of-type(2n) {
            background-color: #EFF9F8;
          }
          .pro-item{
            flex: 1;
            color: #fff;
            &:nth-of-type(2){
              text-align: center;
            }
            &:nth-of-type(3){
              text-align: right;
            }
          }
          .pro-item.pro-item-four{
            &:nth-of-type(3){
              text-align: center;
            }
            &:nth-of-type(4){
              text-align: right;
            }
          }
          .col-item {
              // flex: 1;
              width: 33%;
              flex-wrap: wrap;
              text-align: center;
              padding: 1.3333vw 0 ;
              &:first-of-type{
                text-align: left !important;
              }
              &:last-of-type{
                text-align: right !important;
              }
          }
        }
        .circle-box{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin: 8vw auto;
          padding: 0 1.3333vw;
          width: 35.2vw;
          height: 35.2vw;
          text-align: center;
          border-radius: 50%;
          border: 2.6667vw solid #F0A248;
          .status{
            margin-top:1.3333vw;
            font-size:4vw;
            font-weight:550;
          }
        }
        .target{
          margin-top:4vw;
          width:100%;
          .target-title{
            display: flex;
            align-items: center;
            padding: 0 5.3333vw;
            font-size:3.7333vw;
            min-height:11.2vw;
            color: #000;
            background-color: rgba(130, 130, 130, 1);
          }
          .target-intro{
             margin-top: 2.6667vw;
             font-weight: normal;
             color: rgba(112, 112, 112, 0.7);
          }
        }
      }
    }
  }
  .bottom{
    padding: 1.0667vw 4vw;
    position: fixed;
    width: 100%;
    height: 16vw;
    bottom: 0;
    button{
      width: 100%;
      height: 13.8667vw;
      line-height: 13.8667vw;
      text-align: center;
      font-size: 4.2667vw;
      color: #fff;
      border-radius: 13.3333vw;
      background-color: #5EDBC9;
    }
  }
}
</style>